<template>
    <div>
        <h2 class="border-bottom pb-2 mb-3"><i class="fa fa-linux"></i> Latitude and Longitude Distance Calculation Report</h2>

        <div class="row">
            <div class="col-12">
                <div class="row">
                    <div class="col-12">
                        <div class="form-inline">
                            <div class="form-group mr-1">
                                <input style="max-width: 150px;" type="text" v-model="filters.distance" class="form-control" placeholder="Distance in miles"/>
                            </div>

                            <div class="form-group mr-1">
                                <select style="max-width: 200px;" v-model="filters.country" class="form-control text-capitalize">
                                    <option value="">-- Any Country --</option>
                                    <option value="US">United States</option>
                                </select>
                            </div>

                            <div class="form-group mr-1" v-if="filters.country == 'US' && !filters.zipcode">
                                <select v-model="filters.state" @change="loadCities" class="form-control">
                                    <option value="" selected>-- Any State --</option>
                                    <option v-for="state in $root.states" :key="state._id" :value="state.code">{{state.code}}</option>
                                </select>
                            </div>

                            <div class="form-group mr-1" v-if="filters.state">
                                <span class="loading-feild">
                                    <select style="max-width: 200px;" v-model="filters.city" class="form-control">
                                        <option value="" selected>-- Any City --</option>
                                        <option v-for="city in cities" :key="city._id" :value="city.city_name">{{city.city_name}}</option>
                                    </select>
                                    <img v-if="cityLoader" style="float:right;" id='loading' width="100px" src="http://rpg.drivethrustuff.com/shared_images/ajax-loader.gif"/>
                                </span>
                            </div>

                            <div class="form-group mr-1" v-if="filters.country && (!filters.state && !filters.zipcode)">
                                -- or --
                            </div>

                            <div class="form-group mr-1" v-if="filters.country && !filters.state">
                                <input style="max-width: 150px;" type="text" v-model="filters.zipcode" class="form-control" placeholder="Zip Code"/>
                            </div>

                            <div class="form-group mr-1">
                                <select v-model="filters.provider" class="form-control">
                                    <option selected value="">--Any Provider--</option>
                                    <option value="didsoft">DidSoft</option>
                                    <option value="crawlera">Crawlera</option>
                                    <option value="flipnode">FlipNode</option>
                                    <option value="homeip">HomeIp</option>
                                    <option value="luminate">Luminate</option>
                                    <option value="proxyrack">Proxyrack</option>
                                    <option value="smartproxy">Smartproxy</option>
                                    <option value="netnut">Netnut</option>
                                    <option value="proxyguys">ProxyGuys</option>
                                    <option value="spider">Spider</option>
                                </select>
                            </div>

                            <ejs-progressbutton ref="progressBtn" style="color: white; background-color: #383838;" content="SEARCH" :duration="10000" :spinSettings="spinSettings" v-on:click.native="search"></ejs-progressbutton>
                        </div>
                    </div>
                </div>
                <hr/>

                <div class="control-section">
                    <div class="content-wrapper">
                        <ejs-grid ref='overviewgrid' id='overviewgrid' :dataSource="proxies" :filterSettings='filterOptions' :allowSorting='true'
                                  :toolbar='toolbar' :toolbarClick='toolbarClick' :allowExcelExport='true' height='auto' rowHeight=38 :enableHover='false'
                                  :allowPaging="true" :pageSettings='pageSettings'>
                            <e-columns>
                                <e-column field='state_name' headerText='State' width='200' :filter='filter'></e-column>
                                <e-column field='city' headerText='City' width='200' :filter='filter'></e-column>
                                <e-column field='distance' headerText='Distance' width='200' :filter='filter'></e-column>
                                <e-column field='ip' headerText='IP Address' width='200' :filter='filter'></e-column>
                                <e-column field='provider' headerText='Provider' width='200' :filter='filter'></e-column>
                            </e-columns>
                        </ejs-grid>
                    </div>
                </div>
            </div>
        </div>
        <ejs-toast ref='elementRef' id='element' title='' content='' :position='position'></ejs-toast>
    </div>
</template>

<script>
import { GridPlugin, Page, Sort, ExcelExport, Toolbar } from '@syncfusion/ej2-vue-grids';
import { ToastPlugin } from "@syncfusion/ej2-vue-notifications";
import Vue from 'vue';
import { ProgressButtonPlugin } from "@syncfusion/ej2-vue-splitbuttons";
import { enableRipple } from '@syncfusion/ej2-base';

enableRipple(true);
Vue.use(ProgressButtonPlugin);
Vue.use(GridPlugin);
Vue.use(ToastPlugin);

import axios from "axios";

export default {
    name: 'Proxies.Distance.Report',
    props:['user'],
    components: {},
    provide: {
        grid: [Page, Sort, ExcelExport, Toolbar]
    },
    data: function () {
        return {
            items: [],
            cityLoader: false,
            cities: [],
            states: [],
            cords: {},
            filterOptions: {
                type: 'Menu'
            },
            filter: {
                type: 'CheckBox'
            },
            filters: {
                country: 'US',
                state: '',
                city: '',
                zipcode: '',
                provider: ''
            },
            toolbar: ['ExcelExport'],
            pageSettings: { pageSize: 25 },
            spinSettings: { duration: 10000000, position: 'Right', width: 20, template: '<div class="template"></div>' },
            position: { X: 'Right', Y: 'Bottom' }
        }
    },
    created: function(){
        this.loadStates();
        this.$root.preloader = false;
        window.addEventListener("keydown", this.windowListener);
    },
    computed: {
        proxies() {
            let items = [];
            for(let i in this.items) {
                let item = this.items[i];
                item.distance = this.calcCrow({$lat1: this.coords.latitude, $lon1: this.coords.longitude, $lat2: item.location.coordinates[1], $lon2: item.location.coordinates[0]});
                items.push(item)
            }
            return items
        }
    },
    methods:{
        toolbarClick: function () {
            this.$refs.overviewgrid.excelExport();
        },
        calcCrow(coords) {
            let { $lat1, $lon1, $lat2, $lon2 } = { ...coords };
            let $R = 6371; // km
            let $dLat = this.toRad($lat2 - $lat1);
            let $dLon = this.toRad($lon2 - $lon1);
            $lat1 = this.toRad($lat1);
            $lat2 = this.toRad($lat2);

            let $a = Math.sin($dLat / 2) * Math.sin($dLat / 2) + Math.sin($dLon / 2) * Math.sin($dLon / 2) * Math.cos($lat1) * Math.cos($lat2);
            let $c = 2 * Math.atan2(Math.sqrt($a), Math.sqrt(1 - $a));
            let $d = $R * $c;
            return `${($d * 0.6213711922).toFixed(1)} mi`;
        },
        toRad($Value) {
            return $Value * Math.PI / 180;
        },
        load: function() {
            let proxy = this;
            this.$refs.overviewgrid.$el.ej2_instances[0].on('data-ready', function () {
                proxy.dReady =  true;
            })
            this.$refs.overviewgrid.$el.addEventListener('DOMSubtreeModified', function () {
                if (proxy.dReady && proxy.stTime && proxy.isDataChanged) {
                    let e = performance.now() - proxy.stTime;
                    proxy.loadTime = "Load Time: <b>" + e.toFixed(0) + "</b><b>ms</b>";
                    proxy.stTime = null;
                    proxy.dReady = false;
                    proxy.isDataChanged = false;
                    proxy.$refs.msgelement.classList.remove('e-hide');
                }
            })
        },
        loadStates: function(){
            let request = {filters:{}};

            if(this.filter.country) request.filters.country_iso_code = this.filter.country;

            axios.get(`${this.$root.serverUrl}/admin/geos/state`, {params:request}).then((resp) => {
                this.states = (resp.data && resp.data.data)? resp.data.data : [];
            });
        },
        loadCities: function(){
            this.cityLoader = true;
            this.cities = [];
            let request = {filters:{}};

            if(this.filters.country) request.filters.country_iso_code = this.filters.country;
            if(this.filters.state) request.filters.subdivision_1_iso_code = this.filters.state;

            axios.get(`${this.$root.serverUrl}/admin/geos/city`, {params:request}).then((resp) => {
                this.cities = (resp.data)? resp.data.data : [];
                this.cityLoader = false;
            }).catch(() => {
                this.$refs.elementRef.show( { title: 'Error !', content: 'A problem has been occurred while fetching cities.', cssClass: 'e-toast-danger' });
                this.cityLoader = false;
            });
        },
        search: function(){
            let request = {filters:{}};

            if(this.filters.distance) request.filters.distance = this.filters.distance;
            else this.$refs.elementRef.show( { title: 'Missing Distance !', content: 'Please Enter a distance', cssClass: 'e-toast-warning' });

            if(this.filters.state) request.filters.state = this.filters.state;

            if(this.filters.state && this.filters.city) request.filters.city = this.filters.city;
            else if(this.filters.state && !this.filters.city) this.$refs.elementRef.show( { title: 'Missing City !', content: 'Please Enter a city', cssClass: 'e-toast-warning' });

            if(!this.filters.state && this.filters.zipcode) request.filters.zipcode = this.filters.zipcode;

            if(!this.filters.state && this.filters.zipcode === '') this.$refs.elementRef.show( { title: 'Missing State or Zip Code !', content: 'Please Enter a State or a Zip Code', cssClass: 'e-toast-warning' });
            if(this.filters.provider) request.filters.provider = this.filters.provider;

            if(!this.filters.distance || (this.filters.state && !this.filters.city) || (!this.filters.city && this.filters.zipcode === '')) {
                setTimeout(() => {this.$refs.progressBtn.progressComplete()})
                return
            }

            this.items = [];

            axios.get(`${this.$root.serverUrl}/admin/ftp/test`, {params:request}).then((resp) => {
                this.items = (resp.data) ? resp.data.data : [];
                this.coords = (resp.data) ? resp.data.coords : [];
                this.$refs.progressBtn.progressComplete()
            }).catch(() => {
                this.$refs.elementRef.show( { title: 'Error !', content: 'A problem has been occurred while fetching your data.', cssClass: 'e-toast-danger' });
                this.$refs.progressBtn.progressComplete()
            });
        },
    }
}
</script>

<style>
@import "../../node_modules/@syncfusion/ej2-vue-grids/styles/material.css";
@import '../../node_modules/@syncfusion/ej2-base/styles/material.css';
@import "../../node_modules/@syncfusion/ej2-buttons/styles/material.css";
@import "../../node_modules/@syncfusion/ej2-popups/styles/material.css";
@import "../../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css";
@import '../../node_modules/@syncfusion/ej2-vue-notifications/styles/material.css';
.loading-feild {
    position: relative;
}
.loading-feild img {
    position: absolute;
    left: -26px;
    top: -31px;
}

.loading-feild select {
    padding: 10px;
}
@keyframes custom-rolling {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
.template {
    border: 2px solid green;
    border-style: dotted;
    border-radius: 50%;
    border-top-color: transparent;
    border-bottom-color: transparent;
    height: 16px;
    width: 16px;
}
.template {
    -webkit-animation: custom-rolling 1.3s linear infinite;
    animation: custom-rolling 1.3s linear infinite;
}
#overviewgrid_excelexport {
    background-color: #28a745 !important;
    color: white !important;
}
.e-btn-icon.e-excelexport.e-icons.e-icon-left {
    color: white !important;
}
</style>
